const BASE_URL = "https://josefinarosacor.commercesuite.com.br/";
const ID_STORE = "802619";
export const API_PRODUCT = BASE_URL + "web_api/products";
export const API_CATEGORY = BASE_URL + "web_api/categories/tree";
export const API_CART = BASE_URL + "web_api/cart";
export const SESSION_CART =
  "https://cors-anywhere.herokuapp.com/https://josefinarosacor.commercesuite.com.br" +
  "/nocache/info.php?loja=" +
  ID_STORE;

/**
 *
 * @param {*} props
 * name, category_id, available, promotion, image, stock
 * @returns
 */
export function PRODUCTS_GET(props) {
  delete props.setInfinite;
  console.log(props);

  if (props["category_id"] === undefined) {
    delete props["category_id"];
  }

  // console.log("props", props);

  const params = new URLSearchParams({
    image: "1",
    ...props,
  });
  return {
    url: `${API_PRODUCT}/?${params}`,
    headers: {
      method: "GET",
    },
  };
}

export function PRODUCT_GET(id) {
  return {
    url: `${API_PRODUCT}/${id}`,
    headers: {
      method: "GET",
    },
  };
}

export function CATEGORIES_GET(id) {
  return {
    url: `${API_CATEGORY}`,
    headers: {
      method: "GET",
    },
  };
}

export function CATEGORY_GET(id) {
  return {
    url: `${API_CATEGORY}/${id}`,
    headers: {
      method: "GET",
    },
  };
}

// export function CART_POST(id, body) {
//   return {
//     url: `${API_CART}`,
//     options: {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         Cart: {
//           session_id: id,
//           product_id: "1",
//           quantity: 1,
//           variant_id: 0,
//         },
//       }),
//     },
//   };
// }

// export function CARTSESSION_GET() {
//   return {
//     url: `${SESSION_CART}`,
//     options: {
//       mode: "cors",
//       headers: {
//         method: "GET",
//         // Accept: "*/*",
//       },
//     },
//   };
// }

export function CARTSESSION_GET() {
  return {
    url: "https://eletrosattelecom.com.br/test.php",
    options: {
      method: "GET",
    },
  };
}

// export function CART_POST(body) {
//   return {
//     url: "https://eletrosattelecom.com.br/test.php",
//     options: {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(body),
//     },
//   };
// }

export function CART_POST(body) {
  return {
    url: "https://eletrosattelecom.com.br/testpost.php",
    options: {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    },
  };
}
