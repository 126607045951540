import React from 'react';
import { CARTSESSION_GET } from './Api';

export const CartContext = React.createContext();

export const CartStorage = ({ children }) => {
  const [cartSession, setCartSession] = React.useState(null);
  // const [data, setData] = React.useState('');

  React.useEffect(() => {
    async function getCartSession() {
      const { url, options } = CARTSESSION_GET();
      const response = await fetch(url, options);
      const json = await response.json();
      // setData(json['_id']);

      setCartSession(json['_id']);
      storage.setItem('cartSession', json['_id']);
    }

    const storage = window.localStorage;

    if (storage.getItem('cartSession')) {
      setCartSession(storage.getItem('cartSession'));
    } else {
      getCartSession();
    }
  }, []);

  // async function getCartSession() {
  //   const { url, options } = CARTSESSION_GET();
  //   const response = await fetch(url, options);
  //   const json = await response.json();
  //   setData(json);
  // }

  // React.useEffect(() => {
  //   console.log(cartSession);
  // }, [cartSession]);

  return (
    <CartContext.Provider value={{ cartSession }}>
      {children}
    </CartContext.Provider>
  );
};

// const CartContext = () => {
//   return <div>CartContext</div>;
// };

// export default CartContext;
