import React from "react";
import ShowcaseList from "./Showcase/ShowcaseList";
// import Api from "./Api";

const Home = () => {
  // const BASE_URL = "https://kt.commercesuite.com.br/";
  // const API_PRODUCT = BASE_URL + "web_api/products";

  // const [products, setProducts] = React.useState("");

  // React.useEffect(() => {
  //   async function getProducts() {
  //     const response = await fetch(API_PRODUCT);
  //     const json = await response.json();
  //     console.log("response", json);

  //     setProducts(json.Products);
  //   }

  //   getProducts();
  // }, [API_PRODUCT]);

  return (
    <div className="showcase">
      <ShowcaseList category_id="1" limit="6" />
      {/* <ul className="showcase-list">
        {products &&
          products.map(
            ({ Product }) =>
              Product.ProductImage[0] && (
                <li key={Product.id}>
                  <div>
                    <img
                      src={Product.ProductImage[0]["https"]}
                      alt={Product.name}
                    />
                  </div>
                  <h3>{Product.name}</h3>
                </li>
              )
          )}
      </ul> */}
      {/* <Api /> */}
    </div>
  );
};

export default Home;
