import React from "react";
import { Link } from "react-router-dom";

const MenuItem = ({ item, link, name, children, ...props }) => {
  if (item) {
    const { Category } = item;
    return (
      <li key={Category.id} {...props}>
        <Link to={`/categoria/${Category.id}/${Category.slug}`}>
          {Category.name}
        </Link>
        {children}
      </li>
    );
  }
  return (
    <li {...props}>
      <Link to={`/${link}`}>{name}</Link>
      {children}
    </li>
  );
};

export default MenuItem;
