import React from "react";
import { Link } from "react-router-dom";
import Image from "../Helper/Image";
import styles from "./ShowcaseItem.module.css";

const ShowcaseItem = ({ product }) => {
  const [onHover, setOnHover] = React.useState(false);
  const [state, setState] = React.useState(false);

  function mouseAction({ type }) {
    // console.log(event);
    setState(true);
    if (!product.ProductImage[1]) return false;
    if (type === "mouseover") return setOnHover(true);
    setOnHover(false);
  }
  return (
    product.ProductImage[0] !== undefined && (
      <li className={styles.showcaseItem}>
        <Link to={`/produto/${product.id}`}>
          {/* {console.log(product.ProductImage[0]["https"])} */}
          {/* <img src={product.ProductImage[0]["https"]} alt={product.name} /> */}
          {onHover === false ? (
            <Image
              src={product.ProductImage[0]["https"]}
              alt={product.name}
              onMouseOver={mouseAction}
              onMouseOut={mouseAction}
              className={state ? "opacityOut" : ""}
            />
          ) : (
            product.ProductImage[1] && (
              <Image
                src={product.ProductImage[1]["https"]}
                alt={product.name}
                onMouseOut={mouseAction}
                className="opacityIn"
                style={{}}
              />
            )
          )}

          <h3 className={styles.productName}>{product.name}</h3>
        </Link>
      </li>
    )
  );
};

export default ShowcaseItem;
