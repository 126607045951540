import React from "react";
import { PRODUCTS_GET } from "../../Api";
import UseFetch from "../../Hooks/UseFetch";
import Error from "../Helper/Error";
import Loading from "../Helper/Loading";
import ShowcaseItem from "./ShowcaseItem";
import styles from "./ShowcaseList.module.css";

const ShowcaseList = (props) => {
  // console.log("props", ...props);
  const [page, setPage] = React.useState("");
  const [category, setCategory] = React.useState(props.category_id);
  const { data, loading, error, request } = UseFetch();
  React.useEffect(() => {
    async function fetchProducts() {
      const { url, options } = PRODUCTS_GET({ ...props });
      const { response, json } = await request(url, options);
      const { Products } = json;

      if (response && response.ok && Products.length < props.limit) {
        props.setInfinite(false);
      }
    }

    if (props.page !== page || props.category_id !== category) {
      fetchProducts();
      setPage(props.page);
      setCategory(props.category_id);
    }

    if (props.category_id !== category) {
      // props.setPages([1]);
      props.setInfinite(true);
    }
  }, [request, props, page, category]);

  if (error) return <Error />;
  if (loading) return <Loading />;
  if (data) {
    if (data["Products"].length <= 0) {
      return <p>Nenhum produto cadastrado na categoria</p>;
    }

    const { Products } = data;
    return (
      <ul className={styles.list}>
        {Products.map(
          ({ Product }) =>
            Product.ProductImage && (
              <ShowcaseItem key={Product.id} product={Product} />
            )
        )}
      </ul>
    );
  } else return null;
};

export default ShowcaseList;
