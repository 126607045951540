import React from "react";
import { Route, Routes } from "react-router-dom";
import UserHeader from "./UserHeader";

const User = () => {
  return (
    <div>
      <UserHeader />
      <Routes>
        <Route path="/" element={<div>Teste</div>} />
        <Route path="/teste" element={<div>Página teste</div>} />
      </Routes>
    </div>
  );
};

export default User;
