import React from "react";
import { Link } from "react-router-dom";
import { CATEGORIES_GET } from "../../Api";
import UseFetch from "../../Hooks/UseFetch";
import MenuNav from "../MenuNav/MenuNav";
import styles from "./MainMenu.module.css";

const Menu = () => {
  const { data, request } = UseFetch();

  React.useEffect(() => {
    async function getCategories() {
      const { url, options } = CATEGORIES_GET();
      await request(url, options);
    }
    getCategories();
  }, [request]);

  return data && <MenuNav data={data} />;
};

export default Menu;
