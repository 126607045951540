import React from 'react';
import { useParams } from 'react-router-dom';
import { CART_POST, PRODUCT_GET } from '../../Api';
import { CartContext } from '../../CartContext';
import UseFetch from '../../Hooks/UseFetch';
// import CartApi from '../Cart/CartApi';
import Error from '../Helper/Error';
import Image from '../Helper/Image';
import Loading from '../Helper/Loading';
import styles from './Product.module.css';

const Product = () => {
  const param = useParams();
  const { data, loading, error, request } = UseFetch();
  // const [cartSession, setCartSession] = React.useState(null);
  const [cartQtd, setCartQtd] = React.useState('');

  const { cartSession } = React.useContext(CartContext);

  // const cartId = CartApi();

  React.useEffect(() => {
    // console.log(CartApi());
    // const cartId = () => CartApi();
    // if (window.localStorage.getItem('cartSession')) {
    // console.log(cartId());
    // setCartSession(CartApi());
    // setCartSession(window.localStorage.getItem('cartSession'));
    // } else {
    // setCartSession(CartApi());
    // window.localStorage.setItem('cartSession', CartApi());
    // }
  }, []);

  React.useEffect(() => {
    console.log(cartSession);
  }, [cartSession]);

  async function handleAddCart() {
    const { url, options } = CART_POST({
      Cart: {
        session_id: localStorage.getItem('cartSession'),
        product_id: param['id'],
        quantity: 1,
        variant_id: 0,
      },
    });

    const response = await fetch(url, options);
    const json = await response.json();
    // console.log(json['code']);
    const result = JSON.parse(json);

    // console.log(result);

    console.log(result['code']);

    if (result['code'] === 201) {
      setCartQtd((quantity) => quantity + 1);
    }

    // console.log(cartId);

    // console.log(json["name"]);
  }

  // React.useEffect(() => {
  //   async function cartAdd() {
  //     const { url, options } = CART_POST({
  //       Cart: {
  //         session_id: localStorage.getItem('cartSession'),
  //         product_id: param['id'],
  //         quantity: 1,
  //         variant_id: 0,
  //       },
  //     });

  //     const response = await fetch(url, options);
  //     const json = await response.json();
  //     console.log(json['code']);
  //     const result = JSON.parse(json);

  //     console.log(result['code']);

  //     // console.log(cartId);

  //     // console.log(json["name"]);
  //   }

  //   cartAdd();
  // }, [param]);

  React.useEffect(() => {
    async function productGet() {
      const { url, options } = PRODUCT_GET(param['id']);

      await request(url, options);
    }

    productGet();
  }, [param, request]);

  // console.log(data);

  // console.log(Product.slug);

  // console.log(param["id"]);

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (data === null) return null;
  if (data) {
    const { Product } = data;

    console.log('Produto', Product);

    return (
      <section className={styles.product}>
        <div className={styles.gallery}>
          <ul className={styles.listImages}>
            {Product['ProductImage'].map((image, index) => (
              <li className={styles.item} key={index}>
                {/* {console.log(image)} */}
                <Image src={image.https} alt="" />
              </li>
            ))}
          </ul>
        </div>{' '}
        <div className={styles.details}>
          <h1>{Product['name']}</h1>
          <span className={styles.priceMain}>
            {Number(Product['price']).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </span>
          <span className={styles.paymentInfo}>
            {Product['payment_option']}
          </span>

          <button className={styles.btnBudget}>Orçamento</button>

          <button onClick={handleAddCart} className={styles.btnBudget}>
            Adicionar no Carrinho
          </button>

          <br />
          {cartQtd && (
            <a
              href={`https://josefinarosacor.commercesuite.com.br/checkout/cart?session_id=${window.localStorage.getItem(
                'cartSession'
              )}&store_id=802619#carrinho`}
              target="_blank"
              rel="noreferrer"
              style={{
                border: '1px solid red',
                padding: '.3em',
                borderRadius: '3px',
              }}
            >
              ver carrinho
            </a>
          )}
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: Product['description'] }}
          />
        </div>
      </section>
    );
  }
};

export default Product;
