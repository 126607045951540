import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Contato from './Components/Contato';
import Home from './Components/Home';
import Header from './Components/Header/Header';
import User from './Components/User/User';
import PrivateRoute from './PrivateRoute';
import Login from './Components/Login/Login';
import Product from './Components/Product/Product';
import Category from './Components/Category/Category';
import { CartStorage } from './CartContext';

function App() {
  return (
    <BrowserRouter>
      <CartStorage>
        <Header />
        <div className="container">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="contato/" element={<Contato />} />
            <Route path="categoria/" element={<Category />} />
            <Route path="categoria/:id/:name" element={<Category />} />
            <Route path="login/" element={<Login />} />
            <Route path="produto/:id" element={<Product />} />
            <Route
              path="user/*"
              element={
                <PrivateRoute>
                  <User />
                </PrivateRoute>
              }
            />
          </Routes>
        </div>
      </CartStorage>
    </BrowserRouter>
  );
}

export default App;
