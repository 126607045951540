import React from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [login, setLogin] = React.useState("");
  const [password, setPassword] = React.useState("");
  const navigate = useNavigate();

  function handleSubmit(event) {
    event.preventDefault();
    if (login === "admin" && password === "admin") {
      window.localStorage.setItem("login", login);
      navigate("/user");
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={login}
        onChange={({ target }) => setLogin(target.value)}
      />
      <br />
      <input
        type="password"
        value={password}
        onChange={({ target }) => setPassword(target.value)}
      />
      <button>Logar</button>
    </form>
  );
};

export default Login;
