import React from "react";
import { NavLink } from "react-router-dom";

const UserHeader = () => {
  return (
    <div>
      <nav>
        <NavLink to="/user">User</NavLink>{" "}
        <NavLink to="/user/teste">teste</NavLink>
      </nav>
    </div>
  );
};

export default UserHeader;
