import React from "react";
import styles from "./MenuNav.module.css";
import MenuNavItem from "./MenuNavItem";

const MenuNavSub = ({ data, name, link, ...props }) => {
  return (
    <>
      {link !== undefined ? (
        <MenuNavItem name={name} {...props}>
          <ul className={styles.level2}>
            {data.Category.map((item) => (
              <MenuNavItem key={item["Category"].id} item={item} />
            ))}
          </ul>
        </MenuNavItem>
      ) : (
        <li {...props}>
          <span>{name}</span>

          <ul className={styles.level2}>
            {data.Category.map((item) => (
              <MenuNavItem key={item["Category"].id} item={item} />
            ))}
          </ul>
        </li>
      )}
    </>
  );
};

export default MenuNavSub;
