import React from "react";
import styles from "./Image.module.css";

const Image = ({ alt, style, className, ...props }) => {
  const [skeleton, setSkeleton] = React.useState(true);

  function handleLoad({ target }) {
    setSkeleton(false);
    if (!style) {
      target.style.opacity = 1;
    } else {
      target.style = "";
    }
  }
  return (
    <div className={styles.wrapper}>
      {skeleton && <div className={styles.skeleton}></div>}
      <img
        onLoad={handleLoad}
        className={styles.img + " " + className}
        alt={alt}
        {...props}
      />
    </div>
  );
};

export default Image;
