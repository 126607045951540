import React from "react";
import { useParams } from "react-router-dom";
import ShowcaseList from "../Showcase/ShowcaseList";

const Category = () => {
  const { id } = useParams();
  const [pages, setPages] = React.useState([1]);
  const [infinite, setInfinite] = React.useState(true);
  const [final, setFinal] = React.useState(id);

  React.useEffect(() => {
    setFinal(id);
  }, [id]);

  React.useEffect(() => {
    let wait = false;
    function infiniteScroll() {
      if (infinite) {
        const scroll = window.scrollY;
        const height = document.body.offsetHeight - window.innerHeight;

        if (scroll > height * 0.75 && !wait) {
          setPages((pages) => [...pages, pages.length + 1]);
          wait = true;

          setTimeout(() => {
            wait = false;
          }, 500);
        }
      }
    }

    window.addEventListener("wheel", infiniteScroll);
    window.addEventListener("scroll", infiniteScroll);

    return () => {
      window.removeEventListener("wheel", infiniteScroll);
      window.removeEventListener("scroll", infiniteScroll);

      if (final !== id) {
        setPages([1]);
      }
    };
  }, [infinite, final, id]);

  // console.log("params", useParams());

  // const BASE_URL = "https://kt.commercesuite.com.br/";
  // const API_PRODUCT = BASE_URL + "web_api/products";

  // const [products, setProducts] = React.useState("");

  // React.useEffect(() => {
  //   async function getProducts() {
  //     const response = await fetch(API_PRODUCT);
  //     const json = await response.json();
  //     console.log("response", json);

  //     setProducts(json.Products);
  //   }

  //   getProducts();
  // }, [API_PRODUCT]);

  // console.log(id);

  // return id ? (
  //   <div className="showcase">
  //     {pages.map((page) => (
  //       <ShowcaseList
  //         key={page}
  //         category_id={id}
  //         // limit={+6}
  //         page={page}
  //         setInfinite={setInfinite}
  //       />
  //     ))}
  //     {!infinite && <p>Chegou ao final dos produtos</p>}
  //   </div>
  // ) : (
  //   <div className="showcase">
  //     <ShowcaseList />
  //   </div>
  // );

  return id ? (
    <div className="showcase">
      {pages.map((page) => (
        <ShowcaseList
          key={page}
          category_id={id}
          limit={+6}
          page={page}
          setInfinite={setInfinite}
        />
      ))}
      {!infinite && <p>Chegou ao final dos produtos</p>}
    </div>
  ) : (
    <div className="showcase">
      <ShowcaseList />
    </div>
  );

  // return (
  //   <div className="showcase">
  //     {/* <ShowcaseList {id ? "" : ""} /> */}
  //     <ShowcaseList category_id={params["category_id"]} />

  //     {/* <ShowcaseList params={params} /> */}
  //     {/* <ul className="showcase-list">
  //       {products &&
  //         products.map(
  //           ({ Product }) =>
  //             Product.ProductImage[0] && (
  //               <li key={Product.id}>
  //                 <div>
  //                   <img
  //                     src={Product.ProductImage[0]["https"]}
  //                     alt={Product.name}
  //                   />
  //                 </div>
  //                 <h3>{Product.name}</h3>
  //               </li>
  //             )
  //         )}
  //     </ul> */}
  //     {/* <Api /> */}
  //   </div>
  // );
};

export default Category;
