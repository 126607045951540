import React from "react";
import styles from "./MenuNav.module.css";
import MenuNavItem from "./MenuNavItem";
import MenuNavSub from "./MenuNavSub";

const MenuNav = ({ data, ...props }) => {
  const [mobile, setMobile] = React.useState(false);
  const [active, setActive] = React.useState(false);

  React.useEffect(() => {
    console.log("erro");

    function handleResize() {
      if (document.body.clientWidth < 768) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }

    if (document.body.clientWidth < 768) {
      setMobile(true);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      console.log("rmoveu");
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function handleClick(event) {
    event.currentTarget.classList.toggle(`${styles.active}`);
    setActive(false);
    console.log(event.target);
  }

  return (
    <nav className={styles.menu}>
      {mobile ? (
        <button
          className={styles.triggerMenu + " " + (active ? styles.active : "")}
          onClick={() => setActive(!active)}
        ></button>
      ) : (
        ""
      )}
      <ul
        className={
          (mobile ? styles.level1Mobile : styles.level1) +
          (active ? " " + styles.active : "")
        }
        onClick={handleClick}
        {...props}
      >
        <MenuNavItem name="Home" link="" />
        {/* <MenuNavItem name="Categoria" link="categoria" /> */}

        {/* {data.Category.map((item) => (
          <MenuNavItem key={item["Category"].id} item={item} />
        ))} */}

        <MenuNavSub name="Categoria" onClick={handleClick} data={data} />
        <MenuNavItem name="Contato" link="contato" />
        <MenuNavItem name="Painel" link="user/*" />
      </ul>
    </nav>
  );
};

export default MenuNav;
